<template>
  <div :class="{ 'has-mouse': hasMouse }" @touchstart="hasMouse = false" @contextmenu="(e) => {
      e.preventDefault();
      return false;
    }
    ">
    <Flipbook class="flipbook" :pages="pages" :pagesHiRes="pagesHiRes" :startPage="pageNum" :numPages="pages.length"
      v-slot="flipbook" :ref="flipbook" @flip-left-start="onFlipLeftStart" @flip-left-end="onFlipLeftEnd"
      @flip-right-start="onFlipRightStart" @flip-right-end="onFlipRightEnd" @zoom-start="onZoomStart"
      @zoom-end="onZoomEnd" :clickToZoom="false">
      <div class="action-bar">
        <left-icon class="btn left" style="color: #d6a686;" :class="{ disabled: !flipbook.canFlipLeft }"
          @click="flipbook.flipLeft" />
        <plus-icon class="btn plus" style="color: #d6a686;" :class="{ disabled: !flipbook.canZoomIn }"
          @click="flipbook.zoomIn" />
        <span class="page-num">
          Page {{ flipbook.page }} sur {{ flipbook.numPages }}
        </span>
        <minus-icon style="color: #d6a686;" class="btn minus" :class="{ disabled: !flipbook.canZoomOut }"
          @click="flipbook.zoomOut" />
        <right-icon style="color: #d6a686;" class="btn right" :class="{ disabled: !flipbook.canFlipRight }"
          @click="flipbook.flipRight" />
        <!--<span style="margin-left: 12px; font-size: 12px">Aller à la page</span>
        <select
          v-model="flipbook.page"
          @change="(e) => handleChangePage(e, flipbook)"
          :class="[
            { disabled: !flipbook.canFlipRight },
            { disabled: !flipbook.canFlipLeft },
          ]"
        >
          <option v-for="(page, index) in pages" :key="index" :value="index + 1">
            {{ index + 1 }}
          </option>
        </select>-->
      </div>
    </Flipbook>
    <PreviewLoaderComponent v-if="!showNewspaper" />
  </div>
  
</template>

<script>
import "vue-material-design-icons/styles.css";
import LeftIcon from "vue-material-design-icons/ChevronLeftCircle";
import RightIcon from "vue-material-design-icons/ChevronRightCircle";
import PlusIcon from "vue-material-design-icons/PlusCircle";
import MinusIcon from "vue-material-design-icons/MinusCircle";
import Flipbook from "flipbook-vue";
import axios from "axios";
import PreviewLoaderComponent from "../components/PreviewLoaderComponent";

export default {
  name: "ReadNews",
  components: {
    Flipbook,
    LeftIcon,
    RightIcon,
    PlusIcon,
    MinusIcon,
    PreviewLoaderComponent,
  },

  data() {
    return {
      pageNum: 1,
      selectPageNum: 1,
      pages: [],
      pagesHiRes: [],
      showNewspaper: false,
      indexImgLoaded: [],
    };
  },
  methods: {
    handleChangePage(e) {
      e.preventDefault();
      const page = e.target.value;
      this.pageNum = page;
      this.selectPageNum = page;
    },
    loadPages(pages) {
      const resultPages = atob(pages).split(",");
      let preloadedImages = [];
      const oldThis = this;
      resultPages.forEach(function (page, index) {
        // Create a new Image object,
        let img = new Image();
        // Set the src attribute to the image link
        img.src = page;
        // Optionally, add an onload handler to do something when the image is loaded
        img.addEventListener("load", function () {
          oldThis.indexImgLoaded.push(index);
          if (
            oldThis.indexImgLoaded.includes(0) &&
            oldThis.indexImgLoaded.includes(1)
          ) {
            oldThis.showNewspaper = true;
          }
        });
        img.addEventListener("error", function () {
          oldThis.showNewspaper = true;
        });
        preloadedImages.push(img.src);
      });
      this.pages = preloadedImages;
    },
  },

  mounted() {
    const code = localStorage.getItem("_");
    if (code) {
      const storagePages = localStorage.getItem("_p");
      if (
        storagePages &&
        storagePages !== undefined &&
        storagePages !== "undefined"
      ) {
        this.loadPages(storagePages);
      } else {
        axios
          .post("https://ms-sopecam.nexah.net/api/v1/readnewspaper", {
            code: code,
          })
          .then((response) => {
            if (response.data.errcode === 200) {
              localStorage.setItem("_p", response.data.data.pages);
              this.loadPages(response.data.data.pages);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } else {
      window.location.assign("https://nyangamag.com");
    }
  },
};
</script>
<style>
.action-bar svg {
  cursor: pointer;
}

select {
  background: rgba(255, 255, 255, 0.4);
  border: 0;
  border-radius: 15px;
  width: 41px;
  height: 26px;
  padding-left: 5px;
  margin-left: 12px;
}
</style>